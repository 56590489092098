export const ENUM_COURIER_CHANNEL_TYPE = {
  cn: {
    value: '0',
    label: '菜鸟',
  },
  dw: {
    value: '8',
    label: '得物',
  },
  jdql: {
    value: '2',
    label: '京东青龙',
  },
  qm: {
    value: '11',
    label: '奇门',
  },
};
