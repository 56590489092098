import { message } from 'antd';
import { EgGridModel, request } from 'egenie-utils';
import type { PaginationData, BaseData } from 'egenie-utils';
import _ from 'lodash';
import { observable, action } from 'mobx';
import { placeColumns } from './columns';

interface IAddressSetting{
  provinceAndCityList: IProvinceCity[];
  disabledCityList: number[];
  checkedCityIdList: number[];
}

interface IProvinceCity{
  provinceName: string;
  cityList: [];
}

export class PlaceStore {
  @observable public visible = false;

  @observable public settingVisible = false;

  @observable public settingLoading = false;

  @observable public warehouseId = 0;

  @observable public provinceList = [];

  @observable public inputVal = '';

  @observable public selectedProvince = '';// 点击的省份

  @observable public cityList = [];

  @observable public inputRef;

  @observable public checkedCityList = [];// 选中城市

  @observable public disabledCityList = [];// 禁用城市

  @action public setInputRef = (ref) => {
    this.inputRef = ref;
  };

  @action public handleInputChange = (val) => {
    this.inputVal = val;
  };

  // 省市区关键字搜索
  @action public handleKeyWordSearch = () => {
    this.inputRef.blur();
    const proCityNodes: HTMLDivElement[] = Array.from(document.querySelectorAll('[class*=checkboxWrapper]'));

    proCityNodes.forEach((node) => {
      if (node.innerText.includes(this.inputVal)) {
        // createELement
        const hightLightText = document.createElement('span');
        hightLightText.innerText = this.inputVal;
        hightLightText.style.backgroundColor = 'yellow';

        // keyWord高亮替换
        node.innerHTML = node.innerHTML.replace(this.inputVal, hightLightText.outerHTML);
      }
    });
  };

  @action private queryWarehouse = (data) => {
    delete data.filterParams;
    return request<PaginationData>({
      url: '/api/cloud/baseinfo/rest/addressWarehouse/getAddressInfo',
      method: 'POST',
      data,
    }).then((res) => {
      this.placeGridModel.rows = res.data.list;
      this.placeGridModel.total = res.data.totalCount;
    });
  };

  @observable public placeGridModel = new EgGridModel({
    columns: placeColumns(this).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    showCheckBox: false,
    showSelectedTotal: false,
    primaryKeyField: 'warehouseId',
    api: {
      onQuery: this.queryWarehouse,
      onPageChange: (page, pageSize) => {
        this.queryWarehouse({
          page,
          pageSize,
        });
      },
      onShowSizeChange: (page, pageSize) => {
        this.queryWarehouse({
          page,
          pageSize,
        });
      },
    },
  });

  // 打开属地管理弹窗
  @action public openPlaceModal = (): void => {
    this.visible = true;
    this.placeGridModel.onQuery();
  };

  // 关闭属地管理弹窗
  @action public closePlaceModal = (): void => {
    this.visible = false;

    // 重置表格页码信息
    this.placeGridModel.current = 1;
    this.placeGridModel.pageSize = 50;
  };

  // 打开设置弹窗
  @action public openSettingModal = (warehouseId: number): void => {
    this.settingVisible = true;
    this.warehouseId = warehouseId;
    this.settingLoading = true;

    // 获取属地数据
    request<BaseData<IAddressSetting>>({
      url: '/api/cloud/baseinfo/rest/addressWarehouse/getCityAndAddressInfo',
      method: 'POST',
      data: { warehouseId },
    }).then((res) => {
      const { provinceAndCityList, checkedCityIdList, disabledCityList } = res.data;
      this.provinceList = provinceAndCityList;
      this.disabledCityList = disabledCityList;
      this.checkedCityList = checkedCityIdList;

      // 不存在选中城市的话默认选中第一个
      if (!checkedCityIdList.length) {
        this.cityList = provinceAndCityList[0].cityList;
      } else {
        // 默认选中第一个选中城市省份
        const checkedCity = checkedCityIdList[0];
        let checkedIndex = -1;
        this.provinceList.forEach((item, index) => {
          const eachCityList = item.cityList.map((item) => item.cityId);
          if (eachCityList.includes(checkedCity)) {
            checkedIndex = index;
            this.selectedProvince = item.provinceName;
          }
        });
        if (checkedIndex === -1) {
          console.log('后端返回数据有问题');
          this.cityList = provinceAndCityList[0].cityList;
        } else {
          this.cityList = provinceAndCityList[checkedIndex].cityList;
        }
      }
    })
      .finally(() => {
        this.settingLoading = false;
      });
  };

  // 处理省份变化
  @action public handleProvinceChange = (name): void => {
    this.cityList = this.provinceList.find((item) => item.provinceName === name).cityList;
    this.selectedProvince = name;
  };

  // 处理省份勾选
  @action public handleSelectProvince = (name, checked) => {
    const dealedCityList = this.provinceList.find((item) => item.provinceName === name).cityList.map((item) => item.cityId);
    if (checked) {
      // 找到属于该省份的已被其他仓库选中的市
      const disabledCityListOwnTo = _.intersection(dealedCityList, this.disabledCityList);
      const addCityList = _.xor(dealedCityList, this.checkedCityList.concat(disabledCityListOwnTo));
      this.checkedCityList.push(...addCityList);
    } else {
      this.checkedCityList = _.difference(this.checkedCityList, dealedCityList);
    }
  };

  // 处理城市勾选
  @action public handleSelectCity = (id, checked) => {
    if (checked) {
      this.checkedCityList.push(id);
    } else {
      this.checkedCityList = this.checkedCityList.filter((item) => item !== id);
    }
  };

  // 关闭设置弹窗
  @action public closeSettingModal = (): void => {
    this.settingVisible = false;
    this.selectedProvince = '';
    this.inputVal = '';
    this.checkedCityList = [];
    this.disabledCityList = [];
  };

  // 更新设置项
  @action public saveSetting = (): void => {
    console.log(Array.from(this.checkedCityList));
    request<BaseData>({
      url: '/api/cloud/baseinfo/rest/addressWarehouse/updateAddressInfo',
      method: 'POST',
      data: {
        cityIds: this.checkedCityList,
        warehouseId: this.warehouseId,
      },
    }).then((res) => {
      message.success(res.data || res.info || '设置成功');
    })
      .finally(() => {
        this.closeSettingModal();
        this.placeGridModel.onQuery();
      });
  };

  // 全选
  @action public allSelect = (): void => {
    const allCityList = this.provinceList.reduce((accu, item) => {
      return accu.concat(item.cityList.map((item) => {
        // 判断是否是disabledCity
        if (!this.disabledCityList.includes(item.cityId)) {
          return item.cityId;
        }
      }));
    }, []);

    // 过滤掉undefined
    this.checkedCityList = allCityList.filter((item) => {
      return item !== undefined;
    });
  };

  // 全不选
  @action public allDrop = (): void => {
    this.checkedCityList = [];
  };

  // 反选
  @action public invertSelect = () => {
    const invertCityList = this.provinceList.reduce((accu, item) => {
      return accu.concat(item.cityList.map((item) => {
        // 判断是否是disabledCity或者已选
        if (!this.disabledCityList.includes(item.cityId) && !this.checkedCityList.includes(item.cityId)) {
          return item.cityId;
        }
      }));
    }, []);

    // 过滤掉undefined
    this.checkedCityList = invertCityList.filter((item) => {
      return item !== undefined;
    });
  };
}
