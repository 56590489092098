import React from 'react';
import styles from './index.less';

export const Title = (props: { text: string; }) => {
  return (
    <div className={styles.titleWrapper}>
      <div className={styles.decorateStripe}/>
      <div>
        {props.text}
      </div>
    </div>
  );
};

export const Tag = (props: { jdFlag: boolean; }) => {
  const { jdFlag } = props;
  return (
    <div className={`${styles.tagWrapper} ${jdFlag ? styles.yellowTag : styles.greenTag }`}>
      {jdFlag ? '直营' : '加盟'}
    </div>
  );
};

