import type { PaginationData } from 'egenie-common';
import { MainSubStructureModel, request } from 'egenie-utils';
import type { ICourier, IWayBill } from '../interface';
import type { Store } from '../store';
import { wayBillColumns, mainGridColumns } from './columns';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    pageId: '60102',
    grid: {
      // @ts-ignore
      columns: mainGridColumns(context),
      rows: [],
      primaryKeyField: 'courierCompanyId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      pageSize: 50,
    },

    buttons: [
      {
        text: '新增',
        icon: 'icon-add',
        handleClick: () => {
          context.courierStore.openCourierModal(false);
        },
      },

      // {
      //   text: '启用',
      //   icon: 'icon-setup',
      //   permissionId: '3',
      //   handleClick: () => {
      //     context.enableCourier(true, '');
      //   },
      // },
      // {
      //   text: '禁用',
      //   icon: 'icon-setup',
      //   permissionId: '4',
      //   handleClick: () => {
      //     context.enableCourier(false, '');
      //   },
      // },
      {
        text: '可到地设置',
        icon: 'icon-setup',
        permissionId: '9999999',
        handleClick: () => {
          context.placeStore.openPlaceModal();
        },
      },
      {
        text: '指定商品设置',
        icon: 'icon-setup',
        permissionId: '9999999',
        handleClick: () => {
          context.settingProductStore.openModal();
        },
      },
    ],

    // 主表查询api
    api: {
      onQuery: (params) => {
        const filterItems = context.normalProgramme.filterItems.params;
        const {
          filterParams,
          ...rest
        } = params;
        console.log(params);
        return request<PaginationData<ICourier>>({
          url: '/api/cloud/wms/rest/courier/queryCompany',
          method: 'POST',
          data: {
            ...filterItems,
            ...rest,
          },
        }).then((res) => {
          if (res && Array.isArray(res.data?.list)) {
            return { data: res.data };
          } else {
            return Promise.reject();
          }
        });
      },
    },
    subTables: {
      activeTab: 'wayBill',
      tabsFlag: { inited: { wayBill: true }},

      // @ts-ignore
      list: [wayBillGrid(context)],
    },
  });
}

const wayBillGrid = (context: Store) => {
  return {
    tab: {
      name: '面单渠道',
      value: 'wayBill',
    },
    grid: {
      columns: wayBillColumns(context),
      rows: [],
      pageSize: 50,
      primaryKeyField: 'courierId',
      sortByLocal: false,
      showCheckBox: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: ({
        pid,
        data,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;

        return request<PaginationData<IWayBill>>({
          url: '/api/cloud/wms/rest/courier/queryWaybillChannel',
          method: 'POST',
          data: {
            courierCompanyId: pid,
            ...data,
          },
        }).then((res) => {
          return {
            data: res.data,
            status: 'Successful',
          };
        });
      },
    },
  };
};

