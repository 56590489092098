import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import React from 'react';
import type { ICourierItem, ICommonDict, ICompanyDetail } from '../interface';

export default class Store {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public amFlag = false;// 新增或编辑 true为编辑

  @observable public visible = false;// 弹窗显隐

  @observable public saveLoading = false;// 保存按钮loading

  @observable public modalLoading = false;// 弹窗页loading

  @observable public selectedCourier = {
    courierName: '',
    cpCode: '',
  };// 选中快递

  @observable public courierId = '';

  @observable public commonlyUsedCouriers = [];// 常用快递

  @observable public courierKeyword = '';// 关键词

  @observable public otherCouriers = [];//  其他快递

  @observable public searchResultCouriers = [];// 查询得到的快递公司结果

  @observable public searchFlag = false;// 是否为搜索状态

  @observable public currentStep = 0;// 当前处于第几步

  @observable public deliveryOptions = [];// 配送方式

  @observable public settleTypeOptions = [];// 支付类型

  @observable public formRef = React.createRef<FormInstance>();

  @action public handleCourierWordChange = (e) => {
    this.courierKeyword = e.target.value.trim();
  };

  @action public handleKeyWordSearch = () => {
    this.searchFlag = Boolean(this.courierKeyword);
    this.searchResultCouriers = [];
    if (this.searchFlag) {
      this.searchResultCouriers.push(...this.commonlyUsedCouriers.filter((item) => item.courierName.includes(this.courierKeyword)));
      this.searchResultCouriers.push(...this.otherCouriers.filter((item) => item.courierName.includes(this.courierKeyword)));
    }
  };

  @action public handleCourierChange = (val) => {
    this.selectedCourier = val;
  };

  @action public openCourierModal = async(flag: boolean, id?) => {
    this.visible = true;
    this.modalLoading = true;
    this.amFlag = flag;
    this.currentStep = Number(this.amFlag);
    try {
      await this.initData();

      if (flag) {
        const res = await request<BaseData<ICompanyDetail>>({
          url: `/api/cloud/wms/rest/courier/queryCompany/${id}`,
          method: 'POST',
        });

        const { courierName, cpCode, courierCompanyId, deliverType, courierSettleType } = res.data;
        this.selectedCourier.courierName = courierName;
        this.selectedCourier.cpCode = cpCode;
        this.courierId = courierCompanyId || id;
        res.data.deliverType = String(deliverType);
        res.data.courierSettleType = String(courierSettleType);
        this.formRef.current?.setFieldsValue(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.modalLoading = false;
    }
  };

  @action public closeCourierModal = () => {
    this.amFlag = false;
    this.currentStep = 0;
    this.searchFlag = false;
    this.courierKeyword = '';
    this.commonlyUsedCouriers = [];
    this.otherCouriers = [];
    this.searchResultCouriers = [];
    this.selectedCourier.courierName = '';
    this.selectedCourier.cpCode = '';
    this.saveLoading = false;
    this.formRef.current?.resetFields();
    this.visible = false;
  };

  @action public goStep = (next: boolean) => {
    next ? this.currentStep++ : this.currentStep--;
  };

  // 确定
  @action public save = () => {
    this.formRef.current?.validateFields().then((data) => {
      const { courierName, cpCode } = this.selectedCourier;
      Object.assign(data, {
        courierName,
        cpCode,
      });

      // 编辑的话传id新增的话传enable
      this.amFlag ? Object.assign(data, { courierCompanyId: this.courierId }) : null;
      this.saveLoading = true;
      request<BaseData>({
        url: this.amFlag ? '/api/cloud/wms/rest/courier/editCompany' : '/api/cloud/wms/rest/courier/addCompany',
        method: 'POST',
        data,
      }).then((res) => {
        message.success(`${this.amFlag ? '编辑' : '新增'}成功`);
        this.parent.mainSubGridModel.onRefresh();
        this.closeCourierModal();
      })
        .finally(() => {
          this.saveLoading = false;
        });
    });
  };

  // 获取快递公司
  @action public initData = async() => {
    // 快递公司
    if (!this.amFlag) {
      const courierRes = await request<BaseData<ICourierItem[]>>({
        url: '/api/cloud/wms/rest/courier/dict',
        method: 'POST',
      });

      // 区分常用和其他
      courierRes.data.forEach((courier) => {
        courier.commonUse ? this.commonlyUsedCouriers.push(courier) : this.otherCouriers.push(courier);
      });
    }

    const deliveryRes = await request<ICommonDict[]>({ url: '/api/cloud/baseinfo/rest/common/dict?type=deliver_type' });
    const courierTypeRes = await request<ICommonDict[]>({ url: '/api/cloud/baseinfo/rest/common/dict?type=courier_settle_type' });

    this.deliveryOptions = this.parent.mapOptions(deliveryRes, 'code', 'name');
    this.settleTypeOptions = this.parent.mapOptions(courierTypeRes, 'code', 'name');
  };
}
