import { SearchOutlined } from '@ant-design/icons';
import { Radio, Select, Form, Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { Title } from '../component';
import styles from './index.less';
import type Store from './store';

const { Item } = Form;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const StepOne = observer((props: { store: Store; }) => {
  const { courierKeyword, handleCourierWordChange, handleKeyWordSearch, searchFlag, handleCourierChange, selectedCourier } = props.store;
  return (
    <div>
      <Input
        allowClear
        className={styles.searchInput}
        onBlur={handleKeyWordSearch}
        onChange={handleCourierWordChange}
        onPressEnter={handleKeyWordSearch}
        placeholder="请输入快递公司关键词"
        suffix={(
          <SearchOutlined
            className={styles.searchIcon}
            onClick={handleCourierWordChange}
          />
        )}
        value={courierKeyword}
      />
      <Radio.Group
        className={styles.radioClass}
        onChange={(e) => {
          handleCourierChange(e.target.value);
        }}
        value={selectedCourier}
      >
        {searchFlag ? <SearchContent store={props.store}/> : <AllCourier store={props.store}/>}
      </Radio.Group>
    </div>
  );
});

const AllCourier = observer((props: { store: Store; }) => {
  const { commonlyUsedCouriers, otherCouriers } = props.store;
  return (
    <>
      <Title text="常用快递"/>
      <div className={styles.courierWrapper}>
        {
          commonlyUsedCouriers.map((item) => (
            <div
              className={styles.eachCourier}
              key={item.cpCode}
            >
              <img
                className={styles.iconStyle}
                src={item.iconUrl}
              />
              <Radio
                value={item}
              >
                {item.courierName}
              </Radio>
            </div>
          ))
        }
      </div>
      <Title text="其他快递"/>
      <div className={styles.courierWrapper}>
        {
          otherCouriers.map((item) => (
            <div
              className={styles.eachCourier}
              key={item.cpCode}
            >
              <Radio value={item}>
                {item.courierName}
              </Radio>
            </div>
          ))
        }
      </div>
    </>
  );
});

const SearchContent = observer((props: { store: Store; }) => {
  const { searchResultCouriers } = props.store;
  return (
    <div className={styles.courierWrapper}>
      {
        searchResultCouriers.length > 0 ? searchResultCouriers.map((item) => (
          <div
            className={styles.eachCourier}
            key={item.cpCode}
          >
            {
              item.iconUrl && (
                <img
                  className={styles.iconStyle}
                  src={item.iconUrl}
                />
              )
            }
            <Radio value={item}>
              {item.courierName}
            </Radio>
          </div>
        )) : (
          <div
            className={styles.noCourierTip}
          >
            没有找到结果哦～
          </div>
        )
      }
    </div>
  );
});

export const StepTwo = observer((props: { store: Store; }) => {
  const { formRef, selectedCourier, settleTypeOptions, deliveryOptions } = props.store;
  const { courierName, cpCode } = selectedCourier;
  return (
    <Form
      {...formLayout}
      initialValues={
        {
          codSupported: false,
          deliverType: '1',
          courierSettleType: '2',
        }
      }
      ref={formRef}
    >
      <Item
        label="快递公司"
        rules={[{ required: true }]}
        wrapperCol={{ span: 14 }}
      >
        <div
          className={styles.courierInputWrapper}
        >
          <Item
            noStyle
          >
            <Input
              disabled
              value={courierName}
            />
          </Item>
          <div className={styles.courierSimple}>
            {cpCode}
          </div>
        </div>
      </Item>
      <Item
        label="快递名称"
        name="courierNick"
        rules={[
          { required: true },
          { max: 20 },
        ]}
      >
        <Input/>
      </Item>
      <Item
        label="支持货到付款"
        name="codSupported"

      >
        <Radio.Group>
          <Radio value>
            是
          </Radio>
          <Radio value={false}>
            否
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        label="配送方式"
        name="deliverType"
        rules={[{ required: true }]}
      >
        <Select options={deliveryOptions}/>
      </Item>
      <Item
        label="支付类型"
        name="courierSettleType"
        rules={[{ required: true }]}
      >
        <Select options={settleTypeOptions}/>
      </Item>
    </Form>
  );
});
