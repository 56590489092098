import { Button, Input, Modal, Radio, Select } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styles from './index.less';
import type { SettingProductStore } from './store';

@observer
export class SettingProductModal extends Component<{ store?: SettingProductStore; }> {
  render(): React.ReactElement {
    const { visible, allProductGrid, hasWarehouseGrid, handleSave, skuNo, openBatchSetModal, handleSearch, handleSkuNoChange, activeTab, closeModal, onTabChange, handleBatchDelete } = this.props.store;
    return (
      <Modal
        maskClosable={false}
        okText="保存"
        onCancel={closeModal}
        onOk={handleSave}
        style={{ height: '80%' }}
        title="设置指定商品"
        visible={visible}
        width="80%"
        wrapClassName={styles.modalWrapper}
      >
        <div className={styles.operateWrapper}>
          <Button onClick={openBatchSetModal}>
            批量设置
          </Button>
          {
            activeTab === 2 && (
              <Button
                onClick={handleBatchDelete}
                style={{ marginLeft: '4px' }}
              >
                批量删除
              </Button>
            )
          }
          <Input
            allowClear
            onChange={(e) => {
              handleSkuNoChange(e.target.value);
            }}
            onPressEnter={handleSearch}
            placeholder="请输入商品SKU查询"
            style={{
              width: '20%',
              margin: '0 4px',
            }}
            value={skuNo}
          />
          <Button
            onClick={handleSearch}
            type="primary"
          >
            查询
          </Button>
        </div>
        <Radio.Group
          onChange={onTabChange}
          value={activeTab}
        >
          <Radio.Button value={1}>
            指定商品
          </Radio.Button>
          <Radio.Button value={2}>
            已指定仓库
          </Radio.Button>
        </Radio.Group>
        {
          activeTab === 1 ? (
            <div style={{ height: '100%' }}>
              <EgGrid store={allProductGrid}/>
            </div>
          ) : (
            <div style={{ height: '100%' }}>
              <EgGrid store={hasWarehouseGrid}/>
            </div>
          )
        }
        <BatchSettingModal store={this.props.store}/>
      </Modal>
    );
  }
}

@observer
class BatchSettingModal extends Component<{ store; }> {
  render(): React.ReactElement {
    const { addSettingVisible, closeBatchSetModal, handleBatchSetting, parent, handleBatchSelectChange, batchSelectVal } = this.props.store;
    return (
      <Modal
        maskClosable={false}
        onCancel={closeBatchSetModal}
        onOk={handleBatchSetting}
        title="选择仓库"
        visible={addSettingVisible}
      >
        <Select
          mode="multiple"
          onChange={(val) => {
            handleBatchSelectChange(val);
          }}
          options={parent.warehouseOptions}
          style={{ width: '100%' }}
          value={batchSelectVal}
        />
      </Modal>
    );
  }
}
