import { Switch } from 'antd';
import React from 'react';

export const columns = (context) => {
  return [
    {
      key: 'branchName',
      name: '月结账号',
      width: 100,
    },
    {
      key: 'branchName',
      name: '网点名称',
      width: 100,
    },
    {
      key: 'branchCode',
      name: '网点代码',
      width: 120,
    },
    {
      key: 'address',
      name: '发货地址',
    },
    {
      key: 'defaultAddress',
      name: '默认地址',
      width: 120,
      formatter: ({ row }) => {
        return (
          <Switch
            checked={row.defaultAddress}
            onChange={(val) => {
              context.handleDefaultAddressChange(row.waybillBranchId, val);
            }}
          />
        );
      },
    },
    {
      key: 'quantity',
      name: '可用余额',
      with: 80,
    },
    {
      key: 'warehouse',
      name: '仓库',
    },
  ];
};
