import { Modal, Spin, Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { StepOne, StepTwo } from './steps';
import type Store from './store';

const CourierCompanyModal = observer((props: { store: Store; }) => {
  const { visible, closeCourierModal, amFlag, currentStep, modalLoading } = props.store;
  return (
    <Modal
      footer={currentStep === 0 ? <FirstStepButtons store={props.store}/> : <SecondStepButtons store={props.store}/>}
      maskClosable={false}
      onCancel={closeCourierModal}
      title={amFlag ? '编辑快递公司' : '新增快递公司'}
      visible={visible}
      width={currentStep === 0 ? 800 : 450}
    >
      <Spin spinning={modalLoading}>
        {
          currentStep === 0 ? <StepOne store={props.store}/> : <StepTwo store={props.store}/>
        }
      </Spin>
    </Modal>
  );
});

const FirstStepButtons = observer((props: { store: Store; }) => {
  const { closeCourierModal, goStep, selectedCourier } = props.store;
  return (
    <>
      <Button onClick={closeCourierModal}>
        取消
      </Button>
      <Button
        disabled={!selectedCourier.courierName}
        onClick={() => {
          goStep(true);
        }}
        type="primary"
      >
        下一步
      </Button>
    </>
  );
});

const SecondStepButtons = observer((props: { store: Store; }) => {
  const { closeCourierModal, goStep, save, saveLoading, amFlag } = props.store;
  return (
    <>
      <Button onClick={closeCourierModal}>
        取消
      </Button>
      <Button
        className={styles.lastStepButton}
        disabled={amFlag}
        onClick={() => {
          goStep(false);
        }}
      >
        上一步
      </Button>
      <Button
        loading={saveLoading}
        onClick={save}
        type="primary"
      >
        确定
      </Button>
    </>
  );
});

export default CourierCompanyModal;

