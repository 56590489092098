import type { FormInstance } from 'antd';
import { message } from 'antd';
import { request, EgGridModel } from 'egenie-utils';
import type { BaseData, PureData } from 'egenie-utils';
import { observable, action, autorun, computed } from 'mobx';
import React from 'react';
import { ENUM_COURIER_CHANNEL_TYPE } from '../constants';
import type { IProductType, IOutlet, IWayBillDetail } from '../interface';
import { columns } from './columns';

export default class Store {
  constructor(parent) {
    this.parent = parent;
    autorun(() => {
      this.gridModel.columns[0].ejlHidden = this.courierType === 1;
      this.gridModel.columns[1].ejlHidden = this.courierType === 0;
      this.gridModel.columns[2].ejlHidden = this.courierType === 0;
      this.gridModel.columns[5].ejlHidden = this.courierType === 0;
      this.gridModel.columns[6].ejlHidden = this.parent.isWms;
    });
  }

  @observable public parent;

  @observable public courierId = '';// 快递公司id

  @observable public channelType = ENUM_COURIER_CHANNEL_TYPE.cn.value;

  @observable public cpCode = '';// 快递代码

  @observable public visible = false;// 弹窗显隐

  @observable public saveLoading = false;// 保存loading

  @observable public waybillChannelId = 0;// 面单渠道id（编辑用）

  @observable public modalLoading = false;// 弹窗loading

  @observable public courierType = 1;// 加盟或直营 1为加盟 0为直营

  @observable public waybillChannelOptions = [];// 面单渠道选项

  @observable public productTypeOptions = [];// 产品类型选项

  @observable public originProductTypeOptions: IProductType[] = [];// 原始产品类型

  @observable public shopAuthOptions = [];// 店铺授权选项

  @observable public fixedTemplateOptions = [];// 固定模版选项

  @observable public formRef = React.createRef<FormInstance>();

  @observable public refreshShopDisable = true;// 自动刷新网点按钮

  @observable public wsVal = '1';// 按仓库或店铺 1为店铺 2为仓库

  @observable public amFlag = false;// 新增或编辑 true为编辑

  @observable public showOutlets = true;// 展示网点配置

  @observable public codeRequired = false;// 商家编码是否为必填

  @observable public customTemplateOptions = [];// 自定义模版选项

  @observable public authShopId = '';// 选择的网点

  @observable public virtualDelivery = false;// 是否为虚拟发货

  @observable public gridModel = new EgGridModel({
    columns: columns(this).map((item) => ({
      ...item,
      resizable: true,
      draggable: false,
    })),
    rows: [],
    pageSize: 50,
    primaryKeyField: 'waybillBranchId',
    sortByLocal: false,
    showCheckBox: false,
    showSelectedTotal: false,
    showPager: false,
    showPagination: false,
    showRefresh: false,
  });

  @computed public get showWaybillCpCode(): boolean {
    return this.channelType != ENUM_COURIER_CHANNEL_TYPE.dw.value && this.channelType != ENUM_COURIER_CHANNEL_TYPE.qm.value;
  }

  @computed public get courierProductRequired(): boolean {
    if (this.channelType != ENUM_COURIER_CHANNEL_TYPE.dw.value && this.channelType != ENUM_COURIER_CHANNEL_TYPE.qm.value) {
      if (this.cpCode) {
        if (this.cpCode.includes('JD') || this.cpCode === 'SF') {
          return true;
        }
      }
    }

    return false;
  }

  // 处理面单渠道变化
  @action public handleWaybillChange = async(val) => {
    this.channelType = val;
    this.formRef.current?.setFieldsValue({
      courierProductId: '',
      waybillCpCode: '',
      authShopId: '',
      fixedPrintId: '',
    });
    this.gridModel.rows = [];
    if (!this.virtualDelivery) {
      this.showOutlets = val != ENUM_COURIER_CHANNEL_TYPE.jdql.value && val != ENUM_COURIER_CHANNEL_TYPE.dw.value && val != ENUM_COURIER_CHANNEL_TYPE.qm.value;// 面单渠道为京东青龙时 不展示网点配置
    }
    this.codeRequired = val == ENUM_COURIER_CHANNEL_TYPE.jdql.value;// 面单渠道为京东青龙时，商家编码必填

    this.modalLoading = true;
    try {
      await this.getProductType(val);// 产品类型
      await this.getAuthShops(val);// 授权店铺
      await this.getFixedTemplates(val);// 固定模板
    } catch (e) {
      console.log(e);
    } finally {
      this.modalLoading = false;
    }
  };

  // 打开面单配置弹窗
  @action public openWaybillModal = async(courierInfo, waybillId?: number) => {
    const { courierType, cpCode, courierCompanyId, deliverType } = courierInfo;
    this.courierType = courierType;// 快递类型
    this.cpCode = cpCode;// 快递代码
    this.courierId = courierCompanyId;// 快递公司id
    this.visible = true;
    this.modalLoading = true;
    this.virtualDelivery = deliverType === 2;// 是否为虚拟发货
    if (this.virtualDelivery) {
      this.showOutlets = false;
    }
    this.amFlag = Boolean(waybillId);
    try {
      await this.initData();
      await this.getCustomTemplates();// 获取自定义模板
      if (this.amFlag) {
        this.waybillChannelId = waybillId;
        const res = await this.getWaybillDetail(waybillId);
        const { channelType } = res.data.waybillChannelVO;
        await this.handleWaybillChange(channelType);
        const {
          courierProductId,
          fixedPrintId,
          authShopId,
          customPrintId,
        } = res.data.waybillChannelVO;

        this.formRef?.current.setFieldsValue({
          ...res.data.waybillChannelVO,
          channelType: String(channelType),
          courierProductId: String(courierProductId),
          fixedPrintId: String(fixedPrintId),
          authShopId: String(authShopId),
          customPrintId: String(customPrintId),
        });

        if (authShopId) {
          this.authShopId = String(authShopId);
          this.refreshShopDisable = false;
        }
        this.gridModel.rows = res.data.relationList;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.modalLoading = false;
    }
  };

  // 获取单条面单渠道数据
  private getWaybillDetail = (waybillId) => {
    return request<BaseData<IWayBillDetail>>({
      url: `/api/cloud/wms/rest/courier/queryWaybillChannel/${waybillId}`,
      method: 'POST',
    });
  };

  @action public closeWaybillModal = () => {
    this.visible = false;
    this.showOutlets = true;
    this.virtualDelivery = false;
    this.originProductTypeOptions = [];
    this.productTypeOptions = [];
    this.fixedTemplateOptions = [];
    this.shopAuthOptions = [];
    this.authShopId = '';
    this.refreshShopDisable = true;
    this.gridModel.rows = [];
    this.formRef.current?.resetFields();
    this.courierType = 1;
  };

  // 获取产品类型
  @action public getProductType = (waybillVal) => {
    return request<BaseData<IProductType[]>>({
      url: '/api/tms/cloud/courier/product/query/condition',
      method: 'POST',
      data: {
        cpCode: this.cpCode,
        channelType: waybillVal,
      },
    }).then((res) => {
      this.originProductTypeOptions = res.data || [];
      this.productTypeOptions = this.parent.mapOptions(res.data, 'courierProductId', 'courierProductName');
    });
  };

  // 获取固定模板
  @action public getFixedTemplates = (waybillVal) => {
    return request<BaseData<[]>>({
      url: '/api/tms/cloud/template/list',
      method: 'POST',
      data: {
        cpCode: this.cpCode,
        channel: waybillVal,
      },
    }).then((res) => {
      this.fixedTemplateOptions = this.parent.mapOptions(res.data, 'tmsTemplateId', 'templateName');
    });
  };

  // 获取自定义模版数据
  @action public getCustomTemplates = () => {
    const data = {
      page: '1',
      pageSize: '1000',
      tempType: '0',
    };
    return request<PureData>({
      url: '/api/print/querybyctgr',
      method: 'POST',
      data: new URLSearchParams(data),
    }).then((res) => {
      this.customTemplateOptions = this.parent.mapOptions(res.list, 'id', 'tempName');
    });
  };

  // 获取授权店铺
  @action public getAuthShops = (waybillVal) => {
    return request<BaseData<[]>>({ url: `/api/cloud/wms/rest/courier/getByChannel?channelType=${waybillVal}` }).then((res) => {
      this.shopAuthOptions = this.parent.mapOptions(res.data, 'shopId', 'shopName');
    });
  };

  // 网点变化
  @action public handleAuthShopChange = (shopId) => {
    this.refreshShopDisable = false;
    this.authShopId = shopId;
    this.getOutlets(shopId);
  };

  // 获取网点
  @action public getOutlets = (shopId) => {
    this.gridModel.loading = true;
    request<BaseData<IOutlet[]>>({
      url: '/api/cloud/wms/rest/order/v2/searchWaybill',
      method: 'POST',
      data: {
        shopId,
        cpCode: this.cpCode,
        channelType: this.channelType,
      },
    }).then((res) => {
      this.gridModel.rows = res.data.map((item) => ({
        ...item,
        defaultAddress: false,
        relationType: 1,
      }));
    })
      .finally(() => {
        this.gridModel.loading = false;
      });
  };

  @action public initData = () => {
    // 获取面单渠道
    return request<BaseData<[]>>({
      url: '/api/tms/cloud/waybill/ChannelType',
      method: 'POST',
    }).then((res) => {
      this.waybillChannelOptions = this.parent.mapOptions(res.data, 'channelType', 'channelName');
    });
  };

  @action public save = () => {
    // 根据产品类型获取id和code
    this.formRef.current?.validateFields().then((data) => {
      // 网点类型中必须包含默认地址
      if (this.showOutlets && this.gridModel.rows.every((item) => !item.defaultAddress)) {
        message.warn('请检查网点信息');
        return;
      }

      const productId = data.courierProductId;
      let productData = {};

      // 产品类型
      const selectedCourier = this.originProductTypeOptions.find((item) => item.courierProductId == productId);
      if (selectedCourier) {
        productData = {
          courierProductName: selectedCourier.courierProductName,
          courierProductCode: selectedCourier.courierProductCode,
          courierProductId: selectedCourier.courierProductId,
        };
      }

      Object.assign(data, productData, { courierCompanyId: this.courierId });

      this.amFlag && Object.assign(data, { courierId: this.waybillChannelId });

      // 处理网点信息
      const relationList = this.gridModel.rows.map((item) => ({
        relationType: this.parent.isWms ? 2 : this.wsVal,
        waybillBranchId: item.waybillBranchId,
        defaultAddress: item.defaultAddress,
      }));
      this.saveLoading = true;

      const saveData = {
        waybillChannelDO: data,
        relationList,
      };
      request({
        url: this.amFlag ? '/api/cloud/wms/rest/courier/editWaybillChannel' : '/api/cloud/wms/rest/courier/addWaybillChannel',
        method: 'POST',
        data: saveData,
      }).then((res) => {
        message.success(this.amFlag ? '编辑成功' : '添加成功');
        this.parent.mainSubGridModel.subTablesModel.cursorTabModel.onQuery();
        this.closeWaybillModal();
      });
    })
      .finally(() => {
        this.saveLoading = false;
      });
  };

  @action public handleDefaultAddressChange = (id, checked) => {
    // 默认地址只能开一个
    if (checked) {
      this.gridModel.rows.forEach((item) => {
        item.defaultAddress = false;
      });
    }
    const dealIndex = this.gridModel.rows.findIndex((row) => row.waybillBranchId === id);
    this.gridModel.rows[dealIndex].defaultAddress = checked;
  };

  @action public handleTabsChange = (key) => {
    this.wsVal = key;
  };
}

