import React from 'react';
import type { PlaceStore } from './store';

export const placeColumns = (context: PlaceStore) => {
  return [
    {
      key: 'warehouseNo',
      name: '仓库编码',
    },
    {
      key: 'warehouseName',
      name: '仓库',
    },
    {
      key: 'warehouseTypeName',
      name: '仓库类型',
    },
    {
      key: 'address',
      name: '指定地区',
    },
    {
      key: 'operate',
      name: '操作',
      formatter: ({ row }) => {
        return (
          <a onClick={() => {
            context.openSettingModal(row.warehouseId);
          }}
          >
            设置
          </a>
        );
      },
    },
  ];
};
