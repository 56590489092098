import { Card } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import React from 'react';
import CourierModal from './courierModal';
import styles from './index.less';
import { PlaceManage } from './placeModal';
import { SettingProductModal } from './setProduct';
import { Store } from './store';
import WaybillModal from './wayBillModal';

const store = new Store();

const CourierCompany = () => {
  const { normalProgramme, mainSubGridModel, courierStore, wayBillStore, placeStore, settingProductStore } = store;
  return (
    <div className={styles.page}>
      <Card>
        <NormalProgrammeComponent store={normalProgramme}/>
      </Card>
      <div className={styles.tableWrapper}>
        <MainSubStructure store={mainSubGridModel}/>
      </div>
      <CourierModal store={courierStore}/>
      <WaybillModal store={wayBillStore}/>
      <PlaceManage store={placeStore}/>
      <SettingProductModal store={settingProductStore}/>
    </div>
  );
};

export default CourierCompany;

