import { Switch } from 'antd';
import React from 'react';
import styles from '../index.less';

export const mainGridColumns = (context) => {
  return [
    {
      key: 'deal',
      name: '操作',
      width: 160,
      formatter: ({ row }) => {
        return (
          <div>
            <a
              onClick={() => {
                context.courierStore.openCourierModal(true, row.courierCompanyId);
              }}
            >
              编辑
            </a>
            <a
              className={styles.rowButton}
              onClick={() => {
                context.wayBillStore.openWaybillModal(row);
              }}
            >
              新增面单渠道
            </a>
          </div>
        );
      },
    },
    {
      key: 'courierNick',
      name: '快递名称',
      width: 120,
    },
    {
      key: 'courierName',
      name: '快递公司',
      width: 120,
    },
    {
      key: 'codSupported',
      name: '支持货到付款',
      width: 100,
      formatter: ({ row }) => {
        return (
          row.codSupported ? '是' : '否'
        );
      },
    },
    {
      key: 'deliverTypeLabel',
      name: '配送方式',
      width: 100,
    },
    {
      key: 'courierSettleTypeLabel',
      name: '支付类型',
      width: 80,
    },
    {
      key: 'courierTypeLabel',
      name: '快递类型',
      width: 100,
    },
    {
      key: 'enable',
      name: '启用状态',
      width: 100,
      formatter: ({ row }) => {
        return (
          <Switch
            checked={row.enable}
            onChange={(checked) => {
              context.enableCourier(checked, row.courierCompanyId);
            }}
          />
        );
      },
    },
    {
      key: 'beDefault',
      name: '默认快递',
      width: 100,
      formatter: ({ row }) => {
        return (
          <Switch
            checked={row.beDefault}
            onChange={(checked) => {
              context.setDefaultCourier(checked, row.courierCompanyId);
            }}
          />
        );
      },
    },
    {
      key: 'createTime',
      name: '创建时间',
      width: 150,
    },
    {
      key: 'lastUpdateTime',
      name: '更新时间',
      width: 150,
    },
  ];
};

export const wayBillColumns = (context) => {
  return [
    {
      key: 'deal',
      name: '操作',
      width: 120,
      formatter: ({ row }) => {
        return (
          <div>
            <a onClick={() => {
              context.editWaybillChannel(row.courierId);
            }}
            >
              编辑
            </a>
            {/* <a
              className={styles.rowButton}
              onClick={() => {
                context.deleteWaybillChannel(row.courierId);
              }}
            >
              删除
            </a> */}
          </div>
        );
      },
    },
    {
      key: 'channelTypeLabel',
      name: '面单渠道',
      width: 120,
    },
    {
      key: 'courierProductName',
      name: '产品类型',
      width: 120,
    },
    {
      key: 'fixedPrintLabel',
      name: '固定模板',
      width: 200,
    },
    {
      key: 'customPrintLabel',
      name: '自定义模板',
      width: 200,
    },
  ];
};

