import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { EgGridModel, request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { columns } from './columns';

interface ISkuWarehouse{
  pic: string;
  skuNo: string;
  barCode: string;
  productName: string;
  salePrice: string;
  spec: string;
}

export class SettingProductStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public activeTab = 1;

  @observable public visible = false;

  @observable public addSettingVisible = false;

  @observable public updatedInfo = [];// 待更新的sku仓库信息

  @observable public batchSelectVal = [];

  @observable public skuNo = '';

  // 有仓库的商品列表
  @observable public hasWarehouseGrid = new EgGridModel({
    columns: columns(this, true).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    primaryKeyField: 'skuId',
    api: {
      onQuery: (params) => {
        return this.queryWarehouseSkuInfo(params, 1);
      },
      onPageChange: (page, pageSize) => {
        this.queryWarehouseSkuInfo({
          page,
          pageSize,
        }, 1);
      },
      onShowSizeChange: (page, pageSize) => {
        this.queryWarehouseSkuInfo({
          page,
          pageSize,
        }, 1);
      },
    },
  });

  @action public handleSkuNoChange = (val) => {
    this.skuNo = val;
  };

  @action public handleBatchSelectChange = (val) => {
    this.batchSelectVal = val;
  };

  @action public handleSearch = () => {
    if (this.activeTab === 1) {
      this.allProductGrid.onQuery();
    } else {
      this.hasWarehouseGrid.onQuery();
    }
  };

  // 查询仓库与sku信息
  @action public queryWarehouseSkuInfo = (queryParams, hasWarehouse): Promise<unknown> => {
    delete queryParams.filterParams;
    const data = {
      hasWarehouse,
      skuNo: this.skuNo,
    };
    Object.assign(data, queryParams);
    return request<PaginationData<ISkuWarehouse>>({
      url: '/api/cloud/baseinfo/rest/warehouse/getWarehouseSkuInfo',
      method: 'POST',
      data,
    }).then((res) => {
      if (hasWarehouse) {
        this.hasWarehouseGrid.rows = res.data.list || [];
        this.hasWarehouseGrid.total = res.data.totalCount;
        return;
      }
      this.allProductGrid.rows = res.data.list || [];
      this.allProductGrid.total = res.data.totalCount;
    })
      .catch((e) => {
        console.log(`请求列表失败${ e}`);
      });
  };

  // 所有商品列表
  @observable public allProductGrid = new EgGridModel({
    columns: columns(this, false).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    primaryKeyField: 'skuId',
    api: {
      onQuery: (params) => {
        return this.queryWarehouseSkuInfo(params, 0);
      },
      onPageChange: (page, pageSize) => {
        this.queryWarehouseSkuInfo({
          page,
          pageSize,
        }, 0);
      },
      onShowSizeChange: (page, pageSize) => {
        this.queryWarehouseSkuInfo({
          page,
          pageSize,
        }, 0);
      },
    },
  });

  // 打开弹窗
  @action public openModal = (): void => {
    this.visible = true;
    this.allProductGrid.onQuery();
  };

  // 关闭弹窗
  @action public closeModal = (): void => {
    this.visible = false;
    this.skuNo = '';
    this.activeTab = 1;

    // 表格页码重置
    this.allProductGrid.current = 1;
    this.allProductGrid.pageSize = 50;
    this.hasWarehouseGrid.current = 1;
    this.hasWarehouseGrid.pageSize = 50;
  };

  // tab切换
  @action public onTabChange = (e): void => {
    const tabKey = e.target.value;

    // 处理updatedInfo
    if (tabKey === 2) {
      this.updatedInfo = this.updatedInfo.filter((item) => {
        return item.warehouseIds.length > 0;
      });
    }

    // 切换tab是看商品对应sku信息是否保存
    if (this.updatedInfo.length > 0) {
      Modal.confirm({
        title: '提示',
        content: '是否保存当前标签页设置？',
        onOk: () => {
          this.handleSave().then(() => {
            this.changeTab(tabKey);
          });
        },
        onCancel: () => {
          this.updatedInfo = [];
          this.changeTab(tabKey);
        },
      });
    } else {
      this.changeTab(tabKey);
    }
  };

  private changeTab = (tabKey) => {
    this.activeTab = tabKey;
    if (tabKey === 1) {
      this.allProductGrid.onQuery();
    } else {
      this.hasWarehouseGrid.onQuery();
    }
  };

  // 批量删除
  @action public handleBatchDelete = () => {
    const { selectedIds = new Set() } = this.hasWarehouseGrid;
    if (!selectedIds.size) {
      Modal.warning({
        title: '提示',
        content: '请选中要删除掉的商品',
      });
      return;
    }

    Modal.confirm({
      title: '提示',
      content: '确定删除选中的数据吗？',
      onOk: () => {
        return request<BaseData>({
          url: '/api/cloud/baseinfo/rest/warehouse/deleteWarehouseSkuInfo',
          method: 'POST',
          data: { skuIds: Array.from(selectedIds) },
        }).then((res) => {
          this.hasWarehouseGrid.onQuery();
          message.success('删除成功');
        });
      },
    });
  };

  // 处理sku的仓库变化
  @action public handleSelectChange = (val, skuId, hasWarehouse: boolean) => {
    // 处理updatedInfo
    const skuIndex = this.updatedInfo.findIndex((item) => item.skuId === skuId);

    if (skuIndex !== -1) {
      this.updatedInfo[skuIndex].warehouseIds = val;
    } else {
      const newSkuInfo = {
        skuId,
        warehouseIds: val,
      };
      this.updatedInfo.push(newSkuInfo);
    }

    // 处理表格
    const rowIndex = hasWarehouse ? this.hasWarehouseGrid.rows.findIndex((item) => item.skuId === skuId) : this.allProductGrid.rows.findIndex((item) => item.skuId === skuId);
    const dealedVal = val.length ? val.toString() : null;
    hasWarehouse ? this.hasWarehouseGrid.rows[rowIndex].specifyWarehouse = dealedVal : this.allProductGrid.rows[rowIndex].specifyWarehouse = dealedVal;
  };

  // 保存
  @action public handleSave = () => {
    if (this.updatedInfo.length === 0) {
      return message.warning('请为商品选择指定仓库');
    }
    return request<BaseData>({
      url: '/api/cloud/baseinfo/rest/warehouse/updateWarehouseSkuInfo',
      method: 'POST',
      data: this.updatedInfo,
    }).then((res) => {
      this.updatedInfo = [];
      this.allProductGrid.onQuery();
      this.hasWarehouseGrid.onQuery();
      message.success(res.info || res.data || '设置成功');
    });
  };

  // 打开批量设置
  @action public openBatchSetModal = () => {
    if ((this.activeTab === 1 && this.allProductGrid.selectedIds.size === 0) || (this.activeTab === 2 && this.hasWarehouseGrid.selectedIds.size === 0)) {
      Modal.warning({
        title: '提示',
        content: '请选中要设置的商品',
      });
      return;
    }
    this.addSettingVisible = true;
  };

  // 关闭批量设置弹窗
  @action public closeBatchSetModal = () => {
    this.addSettingVisible = false;
    this.batchSelectVal = [];
  };

  // 确认批量修改
  @action public handleBatchSetting = () => {
    const selectedIds = this.activeTab === 1 ? this.allProductGrid.selectedIds : this.hasWarehouseGrid.selectedIds;
    const hasWarehouse = this.activeTab === 2;

    // 处理updateInfo
    selectedIds.forEach((item) => {
      this.handleSelectChange(this.batchSelectVal, item, hasWarehouse);
    });
    this.closeBatchSetModal();
  };
}
