import { NormalProgramme } from 'egenie-utils';
import type { Store } from '../store';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'courierNick',
        type: 'input',
        label: '快递名称',
      },
      {
        field: 'codSupported',
        type: 'select',
        label: '支持货到付款',
        data: [
          {
            label: '是',
            value: 'true',
          },
          {
            label: '否',
            value: 'false',
          },
        ],
      },
    ],
    handleSearch: context.mainSubGridModel.gridModel.onQuery,
  });
}
