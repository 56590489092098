import { Select } from 'antd';
import { ImgFormatter } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import type { SettingProductStore } from './store';

export const columns = (context: SettingProductStore, hasWarehouse: boolean) => {
  return [
    {
      key: 'specifyWarehouse',
      name: '指定仓库',
      width: 120,
      formatter: ({ row }) => {
        return (
          <Observer>
            {
              () => {
                return (
                  <Select
                    mode="multiple"
                    onChange={(val) => context.handleSelectChange(val, row.skuId, hasWarehouse)}
                    onClick={(e) => e.stopPropagation()}
                    options={context.parent.warehouseOptions}
                    style={{ width: '100%' }}
                    value={row.specifyWarehouse?.split(',')}
                  />
                );
              }
            }
          </Observer>
        );
      },
    },
    {
      key: 'productName',
      name: '商品名称',
    },
    {
      key: 'productNo',
      name: '商品编码',
    },
    {
      key: 'skuNo',
      name: 'SKU编码',
    },
    {
      key: 'barCode',
      name: '条形码',
    },
    {
      key: 'pic',
      name: '图片',
      width: 50,
      formatter: ({ row }) => {
        return <ImgFormatter value={row.src}/>;
      },
    },
    {
      key: 'colorTypeName',
      name: '颜色',
      width: 50,
    },
    {
      key: 'sizeTypeName',
      name: '尺码',
      width: 50,
    },
  ];
};
