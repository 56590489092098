import { SearchOutlined } from '@ant-design/icons';
import { Modal, Button, Input, Checkbox } from 'antd';
import { EgGrid } from 'egenie-utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import styles from './index.less';
import type { PlaceStore } from './store';

export const PlaceManage = observer((props: { store?: PlaceStore; }): React.ReactElement => {
  const { visible, closePlaceModal, placeGridModel } = props.store;
  return (
    <Modal
      footer={(
        <Button onClick={closePlaceModal}>
          关闭
        </Button>
      )}
      maskClosable={false}
      onCancel={closePlaceModal}
      style={{ height: '80%' }}
      title="属地管理"
      visible={visible}
      width="60%"
      wrapClassName={styles.modalWrapper}
    >
      <div style={{ height: '100%' }}>
        <EgGrid store={placeGridModel}/>
      </div>
      <PlaceSetting store={props.store}/>
    </Modal>
  );
});

// 属地设置弹窗
const PlaceSetting = observer((props: { store?: PlaceStore; }): React.ReactElement => {
  const { settingVisible, inputVal, setInputRef, handleKeyWordSearch, handleInputChange, closeSettingModal, selectedProvince, allSelect, allDrop, invertSelect, provinceList, handleProvinceChange, cityList, checkedCityList, disabledCityList, handleSelectProvince, handleSelectCity, settingLoading, saveSetting } = props.store;
  return (
    <Modal
      maskClosable={false}
      okText="保存"
      onCancel={closeSettingModal}
      onOk={saveSetting}
      style={{
        height: '70%',
        top: '150px',
      }}
      title="仓库属地设置"
      visible={settingVisible}
      width="50%"
      wrapClassName={styles.placeSettingModal}
    >
      <div className={styles.buttonWrapper}>
        <Button
          icon={<i className="icon-on"/>}
          onClick={allSelect}
        >
          全选
        </Button>
        <Button
          icon={<i className="icon-on"/>}
          onClick={allDrop}
        >
          全不选
        </Button>
        <Button
          icon={<i className="icon-on"/>}
          onClick={invertSelect}
        >
          反选
        </Button>
        <Input
          allowClear
          onChange={(e) => {
            handleInputChange(e.target.value);
          }}
          onPressEnter={handleKeyWordSearch}
          placeholder="请输入省市名称搜索"
          ref={setInputRef}
          style={{ width: '40%' }}
          suffix={(
            <SearchOutlined
              className={styles.queryIcon}
              onClick={handleKeyWordSearch}
            />
          )}
          value={inputVal}
        />
      </div>
      <div className={styles.addressWrapper}>
        <div className={styles.provinceWrapper}>
          {
            provinceList.map((item) => (
              <div
                className={`${styles.checkboxWrapper} ${selectedProvince === item.provinceName && styles.activeCheckbox}`}
                key={nanoid(5)}
                onClick={() => {
                  handleProvinceChange(item.provinceName);
                }}
              >
                <Checkbox
                  checked={_.intersection(item.cityList.map((item) => item.cityId), checkedCityList).length === item.cityList.length}
                  disabled={_.intersection(item.cityList.map((item) => item.cityId), disabledCityList).length === item.cityList.length}
                  
                  onChange={(e) => {
                    handleSelectProvince(item.provinceName, e.target.checked);
                  }}
                />
                {item.provinceName}
              </div>
            ))
          }
        </div>
        <div className={styles.cityWrapper}>
          {
            cityList.map((item) => (
              <div
                className={styles.checkboxWrapper}
                key={nanoid(5)}
              >
                <Checkbox
                  checked={checkedCityList.includes(item.cityId)}
                  disabled={disabledCityList.includes(item.cityId)}
                  onChange={(e) => {
                    handleSelectCity(item.cityId, e.target.checked);
                  }}
                />
                {item.cityName}
              </div>
            ))
          }
        </div>
        <div/>
      </div>
    </Modal>
  );
});

