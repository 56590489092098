import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import CourierStore from './courierModal/store';
import { mainSubStructureModel } from './mainTable/mainSubGridModel';
import { normalProgramme } from './mainTable/normalProgramme';
import { PlaceStore } from './placeModal/store';
import { SettingProductStore } from './setProduct/store';
import WaybillStore from './wayBillModal/store';

export class Store {
  public mainSubGridModel = mainSubStructureModel(this);

  public normalProgramme = normalProgramme(this);

  @observable public placeStore = new PlaceStore();

  @observable public settingProductStore = new SettingProductStore(this);

  @observable public isWms = true;

  @observable public courierStore = new CourierStore(this);

  @observable public wayBillStore = new WaybillStore(this);

  @observable public warehouseOptions = [];// 仓库

  // 启用 禁用按钮
  @action public enableCourier = (flag: boolean, ids) => {
    if (!ids) {
      ids = Array.from(this.mainSubGridModel.gridModel.selectedIds).toString();
      if (ids.length < 1) {
        message.warn('请选中快递公司');
        return;
      }
    }
    request<BaseData>({
      url: `/api/cloud/wms/rest/courier/${flag ? 'enableCompany' : 'disableCompany'}`,
      method: 'POST',
      data: { ids },
    }).then((res) => {
      this.mainSubGridModel.onRefresh();
    });
  };

  // 设置默认快递公司
  @action public setDefaultCourier = (flag: boolean, id) => {
    request<BaseData>({
      url: `/api/cloud/wms/rest/courier/default/${id}/${flag ? '1' : '0'}`,
      method: 'POST',
    }).then((res) => {
      this.mainSubGridModel.onRefresh();
    });
  };

  // 删除面单渠道
  @action public deleteWaybillChannel = (id) => {
    Modal.confirm({
      title: '确定删除该条数据吗？',
      onOk: () => {
        return request<BaseData>({
          url: `/api/cloud/wms/rest/courier/deleteWaybillChannel/${id}`,
          method: 'POST',
        }).then((res) => {
          message.success('删除成功');
          this.mainSubGridModel.subTablesModel.cursorTabModel.onQuery();
        });
      },
    });
  };

  // 编辑面单渠道
  @action public editWaybillChannel = (id) => {
    const { cursorRow } = this.mainSubGridModel.gridModel;
    this.wayBillStore.openWaybillModal(cursorRow, id);
  };

  private mapOptions = (list, key, val) => {
    return list.map((item) => ({
      label: item[val],
      value: String(item[key]),
    }));
  };
}
