import { Modal, Input, Select, Form, Row, Col, Tabs, Spin, Alert, Button } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { Tag, Title } from '../component';
import styles from './index.less';
import type Store from './store';

const { Item } = Form;

const { TabPane } = Tabs;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const WaybillModal = observer((props: { store: Store; }) => {
  const {
    visible,
    courierType,
    closeWaybillModal,
    showOutlets,
    modalLoading,
    formRef,
    save,
    saveLoading,
  } = props.store;
  return (
    <Form
      {...formLayout}
      ref={formRef}
    >
      <Modal
        maskClosable={false}
        okButtonProps={{ loading: saveLoading }}
        onCancel={closeWaybillModal}
        onOk={save}
        title={(
          <div className={styles.modalTitle}>
            <div className={styles.titleText}>
              面单配置
            </div>
            <Tag jdFlag={courierType === 0}/>
          </div>
        )}
        visible={visible}
        width={1000}
      >
        <Spin spinning={modalLoading}>
          <ChannelSetting store={props.store}/>
          {showOutlets && <OutletsSetting store={props.store}/>}
          <PrintSetting store={props.store}/>
        </Spin>
      </Modal>
    </Form>

  );
});

const ChannelSetting = observer((props: { store; }) => {
  const {
    productTypeOptions,
    waybillChannelOptions,
    codeRequired,
    handleWaybillChange,
    cpCode,
    amFlag,
    showWaybillCpCode,
    courierProductRequired,
  } = props.store;
  return (
    <>
      {
        !amFlag && (
          <Alert
            banner
            message={(
              <div>
                请先填写
                <span style={{
                  padding: '0 4px',
                  fontWeight: 600,
                }}
                >
                  面单渠道
                </span>
                信息！
              </div>
            )}
            style={{
              width: '20%',
              marginBottom: '12px',
            }}
            type="info"
          />
        )
      }
      <Title text="渠道配置"/>
      <Row
        className={styles.eachContentWrapper}
        gutter={2}
        wrap
      >
        <Col span={8}>
          <Item
            label="面单渠道"
            name="channelType"
            rules={[{ required: true }]}
          >
            <Select
              disabled={amFlag}
              onChange={handleWaybillChange}
              options={waybillChannelOptions}
            />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="产品类型"
            name="courierProductId"
            rules={[{ required: courierProductRequired }]}
          >
            <Select options={productTypeOptions}/>
          </Item>
        </Col>
        <Col
          span={8}
        />

        {
          showWaybillCpCode ? (
            <Col span={8}>
              <Item
                label="商家编码"
                name="waybillCpCode"
                rules={[{ required: codeRequired }]}
              >
                <Input/>
              </Item>
            </Col>
          ) : null
        }

      </Row>
    </>
  );
});

const OutletsSetting = observer((props: { store: Store; }) => {
  const {
    gridModel,
    shopAuthOptions,
    authShopId,
    handleAuthShopChange,
    wsVal,
    handleTabsChange,
    refreshShopDisable,
    getOutlets,
  } = props.store;
  return (
    <>
      <Title text="网点配置"/>
      <div className={styles.eachContentWrapper}>
        <Col
          span={10}
          style={{ display: 'flex' }}
        >
          <Item
            label="关键店铺授权"
            name="authShopId"
          >
            <Select
              onChange={handleAuthShopChange}
              options={shopAuthOptions}
              style={{ width: 216 }}
            />
          </Item>
          <Button
            disabled={refreshShopDisable}
            onClick={() => {
              getOutlets(authShopId);
            }}
            style={{ paddingLeft: 0 }}
            type="link"
          >
            刷新
          </Button>
        </Col>
        {
          !props.store.parent.isWms && (
            <Tabs
              defaultActiveKey={wsVal}
              onChange={handleTabsChange}
            >
              <TabPane
                key="1"
                tab="按仓库"
              />
              <TabPane
                key="2"
                tab="按店铺"
              />
            </Tabs>
          )
        }
        <div className={styles.egGridWrapper}>
          <EgGrid store={gridModel}/>
        </div>
      </div>
    </>
  );
});

const PrintSetting = observer((props: { store: Store; }) => {
  const {
    fixedTemplateOptions,
    customTemplateOptions,
  } = props.store;
  return (
    <>
      <Title text="打印配置"/>
      <Row className={styles.eachContentWrapper}>
        <Col span={8}>
          <Item
            label="固定模板"
            name="fixedPrintId"
            rules={[{ required: true }]}
          >
            <Select options={fixedTemplateOptions}/>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="自定义模板"
            required
            wrapperCol={{ span: 18 }}
          >
            <div
              className={styles.customTemp}
            >
              <Item
                name="customPrintId"
                noStyle
                rules={[
                  {
                    required: true,
                    message: '请选择自定义模板',
                  },
                ]}
              >
                <Select
                  options={customTemplateOptions}
                />
              </Item>
              {/* <a className={styles.setButton}>
                设置
              </a> */}
            </div>
          </Item>
        </Col>
      </Row>
    </>
  );
});

export default WaybillModal;
